import { IonIcon } from '@ionic/react';
import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { ColorModel } from '../../styled';
import { addAlpha, lightenColor } from '../../utils/color.util';
import { ToastSeverity } from '../../types/notification/toast-severity';

const SpinKeyframes = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;
interface SpinAnimationProps {
    speed: string;
}
export const SpinAnimation = css<SpinAnimationProps>`
    animation: ${SpinKeyframes} ${(props) => props.speed} linear infinite;
`;

export const BasicButton = styled.button`
    display: block;
    border-radius: ${(props) => props.theme.border.radius.medium};
    border: none;
    outline: none;
    transform: translateY(0);
    transition: transform 0.4s, background-color 0.4s;
    cursor: pointer;
    &:hover {
        /* transform: translateY(-2px); */
    }
    &:focus:not(:active) {
        outline: solid 3px white;
    }
`;

export const StandardButton = styled(BasicButton)`
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.4)};
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 1em;
    font-weight: 200;
    margin: ${(props) => props.theme.margin.medium};
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.4)};
    }
    &:disabled {
        cursor: default;
        color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
        background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.4)};
    }
    & > ion-icon {
        position: relative;
        top: 4px;
        margin-top: -10px;
        font-size: 20px;
    }
`;

export const PrimaryButton = styled(BasicButton)`
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    background-color: ${(props) => props.theme.color.primary.main};
    border: solid 1px ${(props) => props.theme.color.primary.contrast};
    color: ${(props) => props.theme.color.primary.contrast};
    font-size: 1em;
    font-weight: 600;
    margin: ${(props) => props.theme.margin.medium};
    &:hover {
        background-color: ${(props) => lightenColor(props.theme.color.primary.main, 0.2)};
    }
    &:disabled {
        cursor: default;
        color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
        background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.33)};
    }
    & > ion-icon {
        position: relative;
        top: 4px;
        margin-top: -10px;
        font-size: 20px;
    }
`;

export const BigButton = styled(BasicButton)`
    width: calc(100% - 40px);
    height: 60px;
    background: linear-gradient(
        137deg,
        ${(props) => props.theme.color.primary.main} 0%,
        ${(props) => props.theme.color.secondary.main} 100%
    );
    color: ${(props) => props.theme.color.primary.contrast};
    font-size: 1.2em;
    font-weight: 600;
    &:hover {
        transform: translateY(-4px);
    }
    &:disabled {
        transform: translateY(0);
        cursor: not-allowed;
    }
`;

interface BasicButtonLinkProps {
    disabled?: boolean;
}
export const BasicButtonLink = styled(Link)<BasicButtonLinkProps>`
    border-radius: ${(props) => props.theme.border.radius.medium};
    border: none;
    outline: none;
    transform: translateY(0);
    transition: transform 0.4s, background-color 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:focus:not(:active) {
        outline: solid 3px white;
    }
`;

export const StandardButtonLink = styled(BasicButtonLink)`
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.padding.medium};
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.33)};
    border: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 1em;
    font-weight: 200;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.3)};
    }
    &:disabled {
        cursor: default;
        color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
        background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.33)};
    }
    & > ion-icon {
        position: relative;
        top: 4px;
        margin-top: -10px;
        font-size: 20px;
    }
`;

export const BigButtonLink = styled(BasicButtonLink)<BasicButtonLinkProps>`
    width: calc(100% - 40px);
    height: 60px;
    background: linear-gradient(
        137deg,
        ${(props) => props.theme.color.primary.main} 0%,
        ${(props) => props.theme.color.secondary.main} 100%
    );
    color: ${(props) => props.theme.color.primary.contrast};
    font-size: 1.2em;
    font-weight: 600;
    &:hover {
        transform: translateY(-4px);
    }
    ${(props) =>
        props.disabled
            ? `
        transform: translateY(0);
        cursor: not-allowed;
    `
            : ''}
`;

export const BasicDisabledButtonLink = styled.span<BasicButtonLinkProps>`
    border-radius: ${(props) => props.theme.border.radius.medium};
    border: none;
    outline: none;
    transform: translateY(0);
    transition: transform 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StandardDisabledButtonLink = styled(BasicDisabledButtonLink)`
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.large};
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.33)};
    border: solid 1px ${(props) => props.theme.color.base.contrast};
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 1em;
    font-weight: 200;
    color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    border-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.33)};
`;

export type Severity = 'info' | 'warning' | 'error';

export const getSeverityColor = (theme: DefaultTheme, severity: Severity): ColorModel => {
    switch (severity) {
        case 'error':
            return theme.color.error;
        case 'warning':
            return theme.color.warning;
        case 'info':
            return theme.color.info;
    }
};

export const StyledPopupBadgeHolder = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

interface StyledPopupBadgeProps {
    severity: Severity;
    $show: boolean;
}
export const StyledPopupBadge = styled.div<StyledPopupBadgeProps>`
    background-color: ${(props) => getSeverityColor(props.theme, props.severity).main};
    color: white;
    width: calc(100% - 100px);
    max-width: 600px;
    padding: ${(props) => props.theme.padding.large};
    border-radius: ${(props) => props.theme.border.radius.medium};
    margin-top: ${(props) => (props.$show ? 'calc(10px + var(--ion-safe-area-top, 0))' : '-80px')};
    opacity: ${(props) => (props.$show ? '1' : '0')};
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: margin 0.2s, opacity 0.2s;
    & > ion-icon {
        font-size: 48px;
        margin-right: 20px;
        margin-left: 4px;
    }
`;

interface BannerAreaProps {
    severity: Severity;
}
export const BannerArea = styled.div<BannerAreaProps>`
    background-color: ${(props) => getSeverityColor(props.theme, props.severity).main};
    color: white;
    width: calc(100% - 100px);
    max-width: 600px;
    padding: ${(props) => props.theme.padding.large};
    border-radius: ${(props) => props.theme.border.radius.medium};
    margin-top: calc(10px + var(--ion-safe-area-top, 0));
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: margin 0.2s, opacity 0.2s;
    & > ion-icon {
        font-size: 48px;
        margin-right: 20px;
        margin-left: 4px;
    }
`;

export const PopupBodySection = styled.div``;

export const BasicIcon = styled(IonIcon)`
    pointer-events: none;
`;

export const CollectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

export const IconLinkButton = styled.a`
    z-index: 100;
    padding: 6px;
    margin: 6px;
    background-color: ${(props) => addAlpha(props.theme.color.base.main, 0)};
    border-radius: 8px;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.main, 0.5)};
    }
`;

export const StyledLinkIcon = styled(IonIcon)`
    font-size: 32px;
    color: white;
`;

export const Barberpole = keyframes`
    0% { background-position: -28.284px 0; }
    100% { background-position: 0 0; }
`;
export const BarberpoleThick = keyframes`
    0% { background-position: -84.852px 0; }
    100% { background-position: 0 0; }
`;
// background-size: 200% 100%;
// animation: ${Barberpole} 3s linear infinite;
// background-image: repeating-linear-gradient(
//     45deg,
//     ${props => props.theme.color.base.main},
//     ${props => props.theme.color.base.main} 10px,
//     ${props => props.theme.color.warning.main} 10px,
//     ${props => props.theme.color.warning.main} 20px
// );

interface PaginationAreaProps {
    $fullscreen?: boolean;
    $relative?: boolean;
}
export const PaginationArea = styled.div<PaginationAreaProps>`
    overflow-x: hidden;
    width: 100%;
    ${(props) => (props.$relative ? 'position: relative;' : '')}
    ${(props) =>
        props.$fullscreen
            ? `
        height: 100%;
        overflow: hidden;
        & > div > div {
            overflow: hidden;
        }
    `
            : ''}
`;
interface PaginationAreaInnerProps {
    pageCount: number;
    pageOn: number;
}
export const PaginationAreaInner = styled.div<PaginationAreaInnerProps>`
    position: relative;
    left: -${(props) => props.pageOn * 100}%;
    width: ${(props) => props.pageCount * 100}%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(${(props) => props.pageCount}, ${(props) => 100 / props.pageCount}%);
    grid-template-rows: 100%;
    transition: left 0.5s;
`;
interface PaginationPageProps {
    visible: number;
}
export const PaginationPage = styled.div<PaginationPageProps>`
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    ${(props) =>
        props.visible
            ? ''
            : `
        pointer-events: none;
        user-select: none;
    `}
    transition: opacity 0.5s;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    margin: ${(props) => props.theme.margin.medium};
    margin-top: ${(props) => props.theme.margin.huge};
`;

export const CheckboxArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;
interface CheckboxBoxProps {
    $checked: boolean;
}
export const CheckboxBox = styled.button<CheckboxBoxProps>`
    width: 30px;
    height: 30px;
    min-width: 30px;
    border: solid 1px
        ${(props) =>
            props.$checked ? props.theme.color.base.contrast : addAlpha(props.theme.color.base.contrast, 0.2)};
    background-color: ${(props) => props.theme.color.base.main};
    border-radius: ${(props) => props.theme.border.radius.small};
    margin-right: ${(props) => props.theme.margin.large};
    margin-left: ${(props) => props.theme.margin.small};
    outline: none;
    cursor: pointer;
    & > * {
        opacity: ${(props) => (props.$checked ? 1 : 0)};
        position: relative;
        top: 2px;
        color: ${(props) => props.theme.color.base.contrast};
        font-size: 24px;
    }
    &:focus:not(:active) {
        outline: solid 3px ${(props) => props.theme.color.base.contrast};
    }
`;

export const DropArea = styled.div`
    width: 100%;
    height: 160px;
    /* background-color: ${(props) => props.theme.color.base.dark}; */
    /* color: ${(props) => props.theme.color.base.contrast}; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => props.theme.border.radius.large};
    /* border: dashed 2px ${(props) => props.theme.color.base.contrast}; */
    cursor: pointer;
    background-size: 200% 100%;
    animation: ${Barberpole} 3s linear infinite;
    background-image: repeating-linear-gradient(
        45deg,
        ${(props) => props.theme.color.base.main},
        ${(props) => props.theme.color.base.main} 10px,
        ${(props) => props.theme.color.warning.main} 10px,
        ${(props) => props.theme.color.warning.main} 20px
    );
`;
export const DragDropBody = styled.div`
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-color: ${(props) => props.theme.color.base.main};
    border-radius: ${(props) => props.theme.border.radius.medium};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LoadingBarBack = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.color.base.dark};
    border-radius: 25px;
    width: 100%;
    max-width: 600px;
    height: 50px;
    margin: auto;
    overflow: hidden;
    padding: 10px;
    margin: ${(props) => props.theme.margin.large} 0;
`;
interface LoadingBarProps {
    fillPercent: number;
    $paused?: boolean;
}
export const LoadingBarFill = styled.div.attrs<LoadingBarProps>((props) => ({
    style: {
        width: `${props.fillPercent}%`,
        animationPlayState: props.$paused === true ? 'paused' : 'running',
    },
}))<LoadingBarProps>`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-size: calc(200% + 50px) 100%;
    animation: ${Barberpole} 3s linear infinite;
    transition: width ease 1s;
    background-image: repeating-linear-gradient(
        45deg,
        ${(props) => props.theme.color.primary.main},
        ${(props) => props.theme.color.primary.main} 10px,
        ${(props) => props.theme.color.primary.dark} 10px,
        ${(props) => props.theme.color.primary.dark} 20px
    );
`;
export const LoadingBarDoneFill = styled.div<LoadingBarProps>`
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transition: width ease 1s, opacity ease 1s;
    opacity: ${(props) => (props.fillPercent >= 100 ? 1 : 0)};
    background-color: ${(props) => props.theme.color.good.main};
`;
export const LoadingBarBody = styled.div`
    position: relative;
    width: 100%;
    z-index: 2;
    padding-left: 10px;
`;
export const LoadingBarCenterBody = styled.div<LoadingBarProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity ease 1s;
    opacity: ${(props) => (props.fillPercent >= 100 ? 1 : 0)};
    display: ${(props) => (props.fillPercent >= 100 ? 'flex' : 'none')};
    color: ${(props) => props.theme.color.good.contrast};
    font-size: 40px;
    margin: -10px;
`;

interface ModalAreaProps {
    $visible: boolean;
    $top?: boolean;
}
export const ModalBackground = styled.div<ModalAreaProps>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(${(props) => (props.$visible ? '20px' : '0px')});
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${(props) => (props.$top ? 'flex-start' : 'center')};
    padding-top: ${(props) => (props.$top ? 'var(--ion-safe-area-top, 0)' : 0)};
    z-index: 1000;
    transition: opacity 0.4s, backdrop-filter 2s;
    opacity: ${(props) => (props.$visible ? 1 : 0)};
    ${(props) =>
        props.$visible
            ? ''
            : `
        pointer-events: none;
        user-select: none;
    `}
`;
export const ModalArea = styled.div`
    background-color: ${(props) => props.theme.color.base.main};
    width: calc(100% - 60px);
    max-width: 600px;
    border-radius: ${(props) => props.theme.border.radius.large};
    min-height: 300px;
    max-height: 80vh;
    padding: ${(props) => props.theme.padding.huge};
    margin-top: ${(props) => props.theme.margin.huge};
`;
export const ModalCloseArea = styled.div`
    width: calc(100% - 60px);
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

interface FloatingActionButtonProps {
    bottom?: number;
    right?: number;
}
export const FloatingActionButton = styled.button<FloatingActionButtonProps>`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    /* backdrop-filter: blur(8px); */
    border-radius: 999px;
    right: ${(props) => (props.right ? `${props.right}px` : props.theme.margin.large)};
    bottom: ${(props) => props.bottom ?? 110}px;
    cursor: pointer;
    & > ion-icon {
        font-size: 32px;
        color: ${(props) => props.theme.color.base.contrast};
    }
`;
export const FloatingActionButtonLink = styled(Link)<FloatingActionButtonProps>`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.8)};
    /* backdrop-filter: blur(8px); */
    border-radius: 999px;
    right: ${(props) => (props.right ? `${props.right}px` : props.theme.margin.large)};
    bottom: ${(props) => props.bottom ?? 110}px;
    & > ion-icon {
        font-size: 32px;
        color: ${(props) => props.theme.color.base.contrast};
    }
`;

interface DrawerProps {
    $show: boolean;
}
export const DrawerBackground = styled.div<DrawerProps>`
    position: absolute;
    z-index: 1000;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.6)};
    width: 100vw;
    height: ${(props) => (props.$show ? 100 : 0)}vh;
    bottom: 0;
    /* backdrop-filter: blur(8px); */
    transition: height 0.3s ease;
    overflow: hidden;
`;
export const DrawerArea = styled.div<DrawerProps>`
    position: absolute;
    z-index: 1001;
    background-color: ${(props) => props.theme.color.base.main};
    width: 100vw;
    bottom: ${(props) => (props.$show ? 0 : -100)}px;
    transition: bottom 0.3s ease;
`;
export const DrawerContentArea = styled.div`
    margin: auto;
    width: calc(100% - 20px);
    max-width: 500px;
    margin-top: ${(props) => props.theme.margin.large};
    margin-bottom: 100px;
`;
export const DrawerCloseButton = styled.button`
    border: none;
    outline: none;
    margin: auto;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0)};
    border-radius: ${(props) => props.theme.border.radius.small};
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 24px;
    padding: 6px 24px 0 24px;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.5)};
    }
`;

const getToastColorBySeverity = (severity: ToastSeverity, theme: DefaultTheme): string => {
    switch (severity) {
        case ToastSeverity.Info:
            return theme.color.primary.main;
        case ToastSeverity.Good:
            return theme.color.good.main;
        case ToastSeverity.Warning:
            return theme.color.warning.main;
        case ToastSeverity.Error:
            return theme.color.error.main;
    }
    return 'white';
};

const ToastShadowAnimation = keyframes`
    0% { box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3); }
    100% { box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.5); }
`;
interface ToastHolderProps {
    show: boolean;
}
export const ToastHolder = styled.div<ToastHolderProps>`
    position: static;
    z-index: 10000;
    width: 100%;
    top: 0px;
    left: 0px;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transform: translateY(${(props) => (props.show ? 0 : -100)}px);
    transition: opacity 0.2s, transform 0.2s;
`;
interface ToastBodyProps {
    severity: ToastSeverity;
}
export const ToastBody = styled.div<ToastBodyProps>`
    width: calc(100% - 52px);
    max-width: 500px;
    min-height: 100px;
    margin: calc(var(--ion-safe-area-top, 0) + 26px) auto;
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.padding.large} ${(props) => props.theme.padding.huge};
    background-color: ${(props) => props.theme.color.base.main};
    border-radius: ${(props) => props.theme.border.radius.large};
    border-left: solid 30px ${(props) => getToastColorBySeverity(props.severity, props.theme)};
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    animation: ${ToastShadowAnimation} 0.6s forwards;
`;
