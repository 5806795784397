import { createContext } from 'react';
import { Auth, AuthStatus, RegisterRequest, SignInRequest, ForgotPassRequest, NewPassRequest } from '../types';

export interface AuthBundle {
    auth: Auth | null;
    userId?: number;
    state: AuthStatus;
    errorMessage: string | null;
    successMessage: string | null;
    isLoading: boolean;
    isWaitingOnRefresh: boolean;
    tryBetaCode: (code: string) => void;
    login: (req: SignInRequest) => void;
    tryVerification: () => void;
    resendVerification: () => void;
    register: (req: RegisterRequest) => void;
    logout: () => void;
    refresh: () => void;
    setShowForgotPassword: (v: boolean) => void;
    forgotPassword: (req: ForgotPassRequest) => void;
    newPassword: (req: NewPassRequest) => void;
}

export const AuthContext = createContext<AuthBundle>({
    auth: null,
    userId: undefined,
    state: AuthStatus.LoggedOut,
    errorMessage: null,
    successMessage: null,
    isLoading: false,
    isWaitingOnRefresh: true,
    tryBetaCode: (code) => {},
    login: (req) => {},
    tryVerification: () => {},
    resendVerification: () => {},
    register: (req) => {},
    logout: () => {},
    refresh: () => {},
    setShowForgotPassword: () => {},
    forgotPassword: (req) => {},
    newPassword: (req) => {},
});
export const AuthProvider = AuthContext.Provider;
