import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { ReactNode } from 'react';
import { ModalArea, ModalBackground, ModalCloseArea } from './basic.styled';
import Button from './button.component';

interface ModalProps {
    show: boolean;
    onClose: () => void;
    top?: boolean;
    hideX?: boolean;
    children: ReactNode;
}
const Modal = ({ show, onClose, top, hideX, children }: ModalProps) => {
    return (
        <ModalBackground $visible={show} $top={top} onClick={onClose}>
            <ModalArea onClick={(e) => e.stopPropagation()}>{children}</ModalArea>
            {!hideX && (
                <ModalCloseArea>
                    <Button type="small">
                        <IonIcon icon={closeOutline} />
                    </Button>
                </ModalCloseArea>
            )}
        </ModalBackground>
    );
};

export default Modal;
