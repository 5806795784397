import { useState } from 'react';
import { StyledLabel, StyledLabelText, StyledTextInput, ValidationError } from './form.styled';

interface TextInputProps {
    id: string;
    label: string;
    value: string;
    setValue: (v: string) => void;
    $isPassword?: boolean;
    error?: string;
}
const TextInput = ({ id, label, value, setValue, $isPassword, error }: TextInputProps) => {
    const [focused, setFocused] = useState(false);

    return (
        <>
            <StyledLabel htmlFor={id} $hasInput={value ? true : false}>
                <StyledLabelText>{label}</StyledLabelText>
                <StyledTextInput
                    type={$isPassword ? 'password' : 'text'}
                    id={id}
                    name={id}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    $error={!!error && !focused}
                />
                <ValidationError $show={!!error && !focused}>{error}</ValidationError>
            </StyledLabel>
        </>
    );
};

export default TextInput;
