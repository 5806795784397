import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { useState } from 'react';

const ffmpeg = new FFmpeg();

export const useAudioConverter = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [duration, setDuration] = useState(0);
    const [processedTime, setProcessedTime] = useState(0);

    const convertTimeToSeconds = (time: string): number => {
        const [hours, minutes, seconds] = time.split(':').map(parseFloat);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const convertToMp3 = async (file: File): Promise<File | null> => {
        try {
            setProcessedTime(0);
            setDuration(0);

            if (!ffmpeg.loaded) {
                setLoading(true);
                await ffmpeg.load();
            }

            const inputFileName = file.name;
            const outputFileName = inputFileName.replace(/\.[^/.]+$/, '.mp3');

            ffmpeg.writeFile(inputFileName, await fetchFile(file));
            ffmpeg.on('log', ({ message }) => {
                // console.log(message);
                const durationMatch = message.match(/Duration: (\d+):(\d+):(\d+\.\d+)/);
                if (durationMatch) {
                    const [hours, minutes, seconds] = durationMatch.slice(1).map(parseFloat);
                    const detectedDuration = hours * 3600 + minutes * 60 + seconds;
                    setDuration(detectedDuration);
                }
                const timeMatch = message.match(/time=(\d+:\d+:\d+\.\d+)/);
                if (timeMatch) {
                    const timeString = timeMatch[1];
                    const timeInSeconds = convertTimeToSeconds(timeString);
                    setProcessedTime(timeInSeconds);
                }
            });

            await ffmpeg.exec(['-i', inputFileName, '-b:a', '192k', outputFileName]);

            const data = await ffmpeg.readFile(outputFileName);
            const mp3Blob = new Blob([data], { type: 'audio/mp3' });

            setLoading(false);
            return new File([mp3Blob], outputFileName, { type: 'audio/mp3' });
        } catch (error) {
            console.error('Error converting audio:', error);
            setLoading(false);
            return null;
        }
    };

    return {
        convertToMp3,
        progress: duration > 0 ? Math.min((processedTime / duration) * 100, 100) : 0,
        loading,
    };
};
