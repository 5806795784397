import { useState } from "react";
import { Comment } from '../../../components/typography.styled';
import Form from "../../../components/forms/form.component";
import SubmitButton from "../../../components/forms/submit-button.component";
import TextInput from "../../../components/forms/text-input.component";
import useAuth from "../../../hooks/use-auth";

const ForgotPasswordForm = () => {
    const [ username, setUsername ] = useState("");
    const authBundle = useAuth();

    const onSubmit = () => {
        authBundle.forgotPassword({
            username: username
        });
    };

    return (
        <>
            <Comment margin="0 0 16px 0">
                Enter the email associated with your account and we'll send you a code to reset your password. Heads up, the email might be in your spam folder.
            </Comment>
            <Form onSubmit={onSubmit}>
                <TextInput id="username" label="Email" value={username} setValue={setUsername} />
                <SubmitButton text="Send Recovery Email" isLoading={authBundle.isLoading}/>
            </Form>
        </>
    );
};

export default ForgotPasswordForm;