import { useState } from "react";
import Form from "../../../components/forms/form.component";
import SubmitButton from "../../../components/forms/submit-button.component";
import TextInput from "../../../components/forms/text-input.component";
import useAuth from "../../../hooks/use-auth";

const NewPasswordForm = () => {
    const [ username, setUsername ] = useState("");
    const authBundle = useAuth();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const passwordValidation =
    password.length > 0 && (password.length < 8 || !password.match('[0123456789]'))
        ? 'Password must be at least 8 characters and contain a number.'
        : undefined;
    const confirmValidation =
    passwordConfirm.length > 0 && passwordConfirm !== password ? 'Passwords do not match.' : undefined;

    const onSubmit = () => {
        authBundle.newPassword({
            username: username,
            confirmationcode: code,
            newpassword: password
        });
     
    };

    return (
        <Form onSubmit={onSubmit}>
            <TextInput id="code" label="Code" value={code} setValue={setCode} />
            <TextInput id="username" label="Email" value={username} setValue={setUsername} />
            <TextInput
                id="password"
                label="Password"
                value={password}
                setValue={setPassword}
                $isPassword
                error={passwordValidation}
            />
            <TextInput
                id="confirm-password"
                label="Confirm Password"
                value={passwordConfirm}
                setValue={setPasswordConfirm}
                $isPassword
                error={confirmValidation}
            />
            <SubmitButton text="Reset Password" isLoading={authBundle.isLoading}/>

        </Form>
        
    );
};

export default NewPasswordForm;