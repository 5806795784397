export enum AuthStatus {
    LoggedOut = 0,
    LoggedIn = 1,
    NeedsEmailConfirm = 2,
    NeedsDetails = 3,
    BetaCodeValidated = 104,
    BadBetaCode = 105,
    ShowForgotPasswordForm = 106,
    ForgotPasswordEmailSent = 107,
}
