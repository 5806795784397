import styled from 'styled-components';
import { addAlpha, darkenColor, lightenColor } from '../../utils/color.util';

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: stretch;
`;

interface StyledLabelProps {
    $hasInput: boolean;
}
export const StyledLabel = styled.label<StyledLabelProps>`
    position: relative;
    width: 100%;
    &:focus-within > span${(props) => props.$hasInput && ', & > span'} {
        top: -4px;
        left: 16px;
        font-size: 0.8em;
        padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.medium};
        border-radius: 12px 12px 4px 4px;
    }
`;

export const StyledLabelText = styled.span`
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 1.5em;
    transition: all 0.2s;
    background-color: ${(props) => props.theme.color.base.contrast};
    color: ${(props) => addAlpha(props.theme.color.base.dark, 0.5)};
    border-radius: 25px;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    pointer-events: none;
`;

interface InputProps {
    $error?: boolean;
}

export const StyledTextInput = styled.input<InputProps>`
    width: 100%;
    background-color: ${(props) => props.theme.color.base.contrast};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: 1000px;
    font-size: 1.5em;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.margin.large} 0;
    outline: ${(props) =>
        props.$error ? `solid 3px ${props.theme.color.error.main}` : `solid 0px ${props.theme.color.base.contrast}`};
    transition: outline 0.2s;
    z-index: 5;
`;

interface ValidationErrorProps {
    $show: boolean;
}
export const ValidationError = styled.p<ValidationErrorProps>`
    display: block;
    width: 100%;
    background-color: ${(props) => props.theme.color.error.main};
    border-radius: 6px !important;
    color: ${(props) => props.color ?? props.theme.color.error.contrast} !important;
    padding: 4px 8px;
    margin: 0;
    margin-bottom: ${(props) => (props.$show ? '9px' : '0')};
    margin-top: -8px;
    font-weight: 400;
    font-size: 0.8rem;
    overflow: hidden;
    height: ${(props) => (props.$show ? '28px' : '0')};
    opacity: ${(props) => (props.$show ? '1' : '0')};
    transition: height 0.4s, opacity 0.4s, margin 0.4s;
`;

export const StyledTextArea = styled.textarea`
    width: 100%;
    background-color: ${(props) => props.theme.color.base.contrast};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: ${(props) => props.theme.border.radius.large};
    font-size: 1em;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.margin.large} 0;
    outline: none;
    z-index: 5;
    resize: none;
`;

export const StyledChipInputArea = styled.div`
    width: 100%;
    min-height: 3.5em;
    background-color: ${(props) => props.theme.color.base.contrast};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: ${(props) => props.theme.border.radius.large};
    font-size: 1em;
    padding: ${(props) => props.theme.padding.small};
    margin: ${(props) => props.theme.margin.large} 0;
    outline: none;
    z-index: 5;
    resize: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    cursor: pointer;
`;

export const StyledChipHolderArea = styled.div`
    width: 100%;
    min-height: 3.5em;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.3)};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: ${(props) => props.theme.border.radius.large};
    font-size: 1em;
    padding: ${(props) => props.theme.padding.small};
    margin: ${(props) => props.theme.margin.large} 0;
    outline: none;
    z-index: 5;
    resize: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 150px;
    overflow-y: scroll;
    & > div {
        background-color: ${(props) => props.theme.color.base.contrast};
    }
`;

export const StyledChip = styled.div`
    background-color: ${(props) => darkenColor(props.theme.color.base.contrast, 0.2)};
    border-radius: 100px;
    padding: ${(props) => props.theme.padding.small};
    margin: ${(props) => props.theme.margin.small};
    display: flex;
    align-items: center;
    cursor: default;
    & > span {
        margin: 0 ${(props) => props.theme.padding.medium};
    }
`;
export const StyledChipText = styled.span``;
export const StyledChipDelete = styled.button`
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0)};
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    margin-left: ${(props) => props.theme.margin.small};
    margin-right: ${(props) => props.theme.margin.small};
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.2)};
    }
`;

export const StyledChipSuggestionArea = styled.div`
    width: 100%;
`;
export const StyledChipSuggestion = styled.button`
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0)};
    padding: ${(props) => props.theme.padding.small};
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    border-top: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.5)};
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    }
    &:focus:not(:hover) {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.1)};
    }
`;

export const StyledSubmitButton = styled.input`
    width: calc(100% - 8px);
    height: 60px;
    background-color: ${(props) => props.theme.color.primary.main};
    color: ${(props) => props.theme.color.primary.contrast};
    font-size: 1.2em;
    font-weight: 600;
    margin: ${(props) => props.theme.margin.large} 4px;
    border-radius: ${(props) => props.theme.border.radius.medium};
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.4s, opacity 0.4s;
    &:focus:not(:active) {
        outline: solid 3px white;
    }
    &:hover {
        background-color: ${(props) => darkenColor(props.theme.color.primary.main, 0.2)};
    }
    &:disabled {
        opacity: 0.4;
        background-color: ${(props) => props.theme.color.primary.main} !important;
        cursor: auto;
    }
`;

export const LoadingSubmitButton = styled.div`
    width: calc(100% - 8px);
    height: 60px;
    background: linear-gradient(
        137deg,
        ${(props) => props.theme.color.primary.main} 0%,
        ${(props) => props.theme.color.secondary.main} 100%
    );
    margin: ${(props) => props.theme.margin.large} 4px;
    border-radius: ${(props) => props.theme.border.radius.medium};
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface SmallStyledLabelProps {
    $hasInput: boolean;
    $grayout?: boolean;
}
export const StyledSmallLabel = styled.label<SmallStyledLabelProps>`
    position: relative;
    width: 100%;
    &:focus-within > span${(props) => props.$hasInput && ', & > span'} {
        top: -4px;
        left: 16px;
        font-size: 0.75em;
        padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.medium};
        border-radius: 12px 12px 0 0;
    }
    &:focus-within > *${(props) => props.$hasInput && ', & > *'} {
        background-color: ${(props) => props.theme.color.base.contrast} !important;
    }
    & > * {
        ${(props) =>
            props.$grayout !== true ? `background-color: ${props.theme.color.base.contrast} !important;` : ''}
    }
`;

export const StyledSmallLabelText = styled.span`
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 1em;
    transition: all 0.2s;
    background-color: ${(props) => lightenColor(props.theme.color.base.main, 0.2)};
    color: ${(props) => addAlpha(props.theme.color.base.dark, 0.5)};
    border-radius: 25px;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    pointer-events: none;
`;

export const StyledSmallTextInput = styled.input`
    width: 100%;
    background-color: ${(props) => lightenColor(props.theme.color.base.main, 0.2)};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: 1000px;
    font-size: 1em;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.margin.large} 0;
    outline: none;
    z-index: 5;
    transition: all 0.2s;
`;
export const StyledSmallTextArea = styled.textarea`
    width: 100%;
    background-color: ${(props) => lightenColor(props.theme.color.base.main, 0.2)};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: ${(props) => props.theme.border.radius.medium};
    font-size: 1em;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    margin: ${(props) => props.theme.margin.large} 0;
    outline: none;
    z-index: 5;
    resize: none;
`;

export const LinkInputListArea = styled.div`
    width: 100%;
    min-height: 160px;
    max-height: 160px;
    overflow-y: scroll;
    margin-bottom: ${(props) => props.theme.margin.medium};
    background-color: ${(props) => props.theme.color.base.dark};
    border-radius: ${(props) => props.theme.border.radius.medium};
`;
export const LinkInputItem = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.medium};
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.color.base.contrast};
    border-bottom: solid 1px ${(props) => addAlpha(props.theme.color.base.contrast, 0.5)};
    & > a {
        width: 100%;
        color: ${(props) => props.theme.color.base.contrast};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
            text-decoration: underline !important;
        }
    }
    & > ion-icon {
        font-size: 24px;
        margin-right: ${(props) => props.theme.margin.medium};
    }
    & > div {
        margin-right: ${(props) => props.theme.margin.medium};
    }
`;
export const NoLinksCommentHolder = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const LinkXButton = styled.button`
    outline: none;
    border: none;
    width: 28px;
    height: 28px;
    min-width: 28px;
    background: none;
    background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0)};
    border-radius: ${(props) => props.theme.border.radius.small};
    transition: background-color 0.2s;
    color: ${(props) => props.theme.color.base.contrast};
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => addAlpha(props.theme.color.base.contrast, 0.2)};
    }
`;

interface FullscreenFormProps {
    $visible: boolean;
    viewport: number;
}
export const FullscreenFormBackground = styled.div<FullscreenFormProps>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(${(props) => (props.$visible ? '20px' : '0px')});
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(var(--ion-safe-area-top, 0) + 24px);
    z-index: 1000;
    transition: opacity 0.4s, backdrop-filter 2s;
    opacity: ${(props) => (props.$visible ? 1 : 0)};
    ${(props) =>
        props.$visible
            ? ''
            : `
        pointer-events: none;
        user-select: none;
    `}
`;
export const FullscreenFormBody = styled.div`
    width: calc(100% - 40px);
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const FullscreenFormHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -16px;
    & > * {
        margin: 0;
    }
`;
export const PostBodyPreview = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    max-height: 400px;
    background-color: ${(props) => props.theme.color.base.contrast};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: ${(props) => props.theme.border.radius.large};
    font-size: 1em;
    margin: ${(props) => props.theme.margin.large} 0;
    outline: none;
    z-index: 5;
    resize: none;
    cursor: text;
`;
export const PostBodyPreviewTextArea = styled.div`
    flex-grow: 1;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
`;
export const PostTextAreaBackground = styled.div<FullscreenFormProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${(props) => props.theme.color.base.contrast};
    color: ${(props) => props.theme.color.base.dark};
    border: none;
    border-radius: ${(props) => props.theme.border.radius.large};
    font-size: 1em;
    margin-top: ${(props) => props.theme.margin.large};
    margin-bottom: 10px;
    outline: none;
    z-index: 5;
    resize: none;
    height: ${(props) => props.viewport - 180}px;
    max-height: 400px;
`;
export const StyledPostTextArea = styled.div<FullscreenFormProps>`
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    color: ${(props) => props.theme.color.base.dark};
    font-size: 1em;
    padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.large};
    /* height: ${(props) => props.viewport - 180}px; */
    max-height: 400px;
    outline: none;
    resize: none;
    cursor: text;
`;
export const PostAdditionHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow-x: scroll;
    width: 100%;
    height: 60px;
    min-height: 60px;
    background-color: ${(props) => props.theme.color.base.contrast};
    border-radius: ${(props) => props.theme.border.radius.large};
`;
interface PostAdditionButtonProps {
    $isAt?: boolean;
}
export const PostAdditionButton = styled.button<PostAdditionButtonProps>`
    border: none;
    outline: none;
    border-radius: calc(${(props) => props.theme.border.radius.large} - 2px);
    margin: 4px;
    font-size: 1.8em;
    background-color: rgba(0, 0, 0, 0.1);
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.huge};
    color: ${(props) => props.theme.color.base.dark};
    cursor: pointer;
    white-space: nowrap;
    transition: background-color 0.2s;
    ${(props) => (props.$isAt ? 'padding-top: 0;' : '')}
    &:not(:disabled):hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
    &:disabled {
        color: ${(props) => addAlpha(props.theme.color.base.dark, 0.2)};
        cursor: auto;
    }
    & > ion-icon {
        position: relative;
        font-size: 36px;
        top: 3px;
    }
    & > span {
        font-weight: 500;
        color: ${(props) => props.theme.color.base.dark};
    }
`;
export const PostTagSuggestionButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    outline: none;
    border-radius: calc(${(props) => props.theme.border.radius.large} - 2px);
    margin: 4px;
    font-size: 1em;
    background-color: rgba(0, 0, 0, 0.1);
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.large};
    color: ${(props) => props.theme.color.base.dark};
    cursor: pointer;
    white-space: nowrap;
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
    & > span {
        color: ${(props) => props.theme.color.base.dark};
        width: 100%;
        text-align: center;
    }
`;
export const PostUserSuggestionButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    outline: none;
    border-radius: calc(${(props) => props.theme.border.radius.large} - 2px);
    margin: 4px;
    font-size: 1em;
    background-color: rgba(0, 0, 0, 0.1);
    padding: ${(props) => props.theme.padding.small} ${(props) => props.theme.padding.large};
    padding-left: ${(props) => props.theme.padding.medium};
    color: ${(props) => props.theme.color.base.dark};
    cursor: pointer;
    white-space: nowrap;
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
    & > span {
        color: ${(props) => props.theme.color.base.dark};
        width: 100%;
        text-align: center;
    }
    & > div {
        margin-right: 4px;
    }
`;
export const PostImageThumbnailHolder = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 100%;
    overflow-x: scroll;
    border-top: solid 1px ${(props) => addAlpha(props.theme.color.base.dark, 0.2)};
    padding: ${(props) => props.theme.padding.medium};
`;
interface PostImageThumbnailProps {
    img?: string;
}
export const PostImageThumbnail = styled.div<PostImageThumbnailProps>`
    position: relative;
    width: 80px;
    min-width: 80px;
    height: 80px;
    background-color: ${(props) => addAlpha(props.theme.color.base.dark, 0.2)};
    ${(props) => (props.img ? `background-image: url(${props.img});` : '')}
    background-size: cover;
    background-position: center;
    margin: ${(props) => props.theme.margin.small};
    border-radius: ${(props) => props.theme.border.radius.medium};
    & > span {
        text-align: center;
        margin-top: 20px;
    }
`;
export const PostImageThumbnailXButton = styled.button`
    position: absolute;
    right: 3px;
    top: 3px;
    border: none;
    color: ${(props) => props.theme.color.base.dark};
    background-color: ${(props) => props.theme.color.base.contrast};
    width: 25px;
    height: 25px;
    border-radius: 999px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & > ion-icon {
        font-size: 20px;
    }
    &:hover {
        background-color: ${(props) => darkenColor(props.theme.color.base.contrast, 0.2)};
    }
`;
