import { useState } from 'react';
import PopupBadge from '../../components/basic/popup-badge.component';
import { FlexContainer } from '../../components/components.styled';
import OxPage from '../../components/oxpage.component';
import { A, Body, Comment, Title } from '../../components/typography.styled';
import { AuthStatus } from '../../types/';
import ConfirmEmailForm from './components/confirm-email-form.component';
import CoverText from './components/cover-text.component';
import RegisterForm from './components/register-form.component';
import SignInForm from './components/sign-in-form.component';
import ForgotPasswordForm from './components/forgot-password-form.component';
import UserDetailsPage from './components/user-details.component';
import useAuth from '../../hooks/use-auth';
import NewPasswordForm from './components/new-password-form-component';

const LoginPage = () => {
    const [gotStarted, setGotStarted] = useState(false);
    const [onSignIn, setOnSignIn] = useState(false);
    const authBundle = useAuth();
    const showForgotPassword =
        authBundle.state === AuthStatus.ShowForgotPasswordForm ||
        authBundle.state === AuthStatus.ForgotPasswordEmailSent;

    if (authBundle.state === AuthStatus.NeedsDetails) {
        return <UserDetailsPage />;
    }

    return (
        <OxPage>
            <PopupBadge show={authBundle.errorMessage !== null} severity="error">
                <Body $block>{authBundle.errorMessage}</Body>
            </PopupBadge>
            <PopupBadge show={authBundle.successMessage !== null} severity="info">
                <Body $block>{authBundle.successMessage}</Body>
            </PopupBadge>
            <FlexContainer
                align="center"
                justify="center"
                direction="column"
                padding="30px"
                maxWidth="600px"
                margin="auto"
            >
                {!gotStarted ? (
                    <CoverText onContinue={() => setGotStarted(true)} />
                ) : authBundle.state === AuthStatus.NeedsEmailConfirm ? (
                    <ConfirmEmailForm />
                ) : (
                    <>
                        <Title>{showForgotPassword ? 'Recover Password' : onSignIn ? 'Sign in' : 'Sign up'}</Title>
                        <Comment margin={showForgotPassword ? '0 0 16px 0' : '0 0 64px 0'}>
                            {showForgotPassword
                                ? "Let's get you back into your account"
                                : onSignIn
                                ? 'Welcome back'
                                : "Let's get you plugged into your audience"}
                        </Comment>
                        {onSignIn ? (
                            showForgotPassword ? (
                                authBundle.state === AuthStatus.ForgotPasswordEmailSent ? (
                                    <NewPasswordForm />
                                ) : (
                                    <ForgotPasswordForm />
                                )
                            ) : (
                                <>
                                    <SignInForm />
                                    <Body align="center" padding="30px 0 0 0">
                                        Need an account? <A onClick={() => setOnSignIn(!onSignIn)}>Sign Up</A>
                                    </Body>
                                    <Body align="center" padding="30px 0 0 0">
                                        Forgot your password?{' '}
                                        <A onClick={() => authBundle.setShowForgotPassword(true)}>Recover</A>
                                    </Body>
                                </>
                            )
                        ) : (
                            <>
                                <RegisterForm />
                                <Body align="center" padding="30px 0 0 0">
                                    Already have an account? <A onClick={() => setOnSignIn(!onSignIn)}>Sign In</A>
                                </Body>
                            </>
                        )}
                        <Comment margin="0 0 64px 0">
                            {showForgotPassword ? (
                                <>
                                    <br />
                                    Having any other problems? Contact us!
                                    <A href="mailto:help@oxchord.com"> help@oxchord.com</A> or
                                    <A href="https://www.instagram.com/oxchord.music" target="_blank">
                                        &nbsp;@oxchord.music
                                    </A>{' '}
                                    on Instagram
                                </>
                            ) : null}
                        </Comment>
                    </>
                )}
            </FlexContainer>
        </OxPage>
    );
};

export default LoginPage;
