import { useState } from 'react';
import Form from '../../../components/forms/form.component';
import SubmitButton from '../../../components/forms/submit-button.component';
import TextInput from '../../../components/forms/text-input.component';
import useAuth from '../../../hooks/use-auth';

const RegisterForm = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const authBundle = useAuth();

    const usernameValidation = undefined;
    const emailValidation = undefined;
    const passwordValidation =
        password.length > 0 && (password.length < 8 || !password.match('[0123456789]'))
            ? 'Password must be at least 8 characters and contain a number.'
            : undefined;
    const confirmValidation =
        passwordConfirm.length > 0 && passwordConfirm !== password ? 'Passwords do not match.' : undefined;

    const isValid =
        !usernameValidation &&
        !emailValidation &&
        !passwordValidation &&
        !confirmValidation &&
        username &&
        email &&
        password &&
        passwordConfirm;

    const onSubmit = () => {
        authBundle.register({
            username: username,
            email: email,
            password: password,
            passwordConfirm: passwordConfirm,
        });
    };

    return (
        <Form onSubmit={onSubmit}>
            <TextInput
                id="username"
                label="Username"
                value={username}
                setValue={setUsername}
                error={usernameValidation}
            />
            <TextInput id="email" label="Email" value={email} setValue={setEmail} error={emailValidation} />
            <TextInput
                id="password"
                label="Password"
                value={password}
                setValue={setPassword}
                $isPassword
                error={passwordValidation}
            />
            <TextInput
                id="confirm-password"
                label="Confirm Password"
                value={passwordConfirm}
                setValue={setPasswordConfirm}
                $isPassword
                error={confirmValidation}
            />
            <SubmitButton text="Sign Up" isLoading={authBundle.isLoading} disabled={!isValid} />
        </Form>
    );
};

export default RegisterForm;
