import LoadingSpinner from '../basic/loading-spinner.component';
import { LoadingSubmitButton, StyledSubmitButton } from './form.styled';

interface SubmitButtonProps {
    text: string;
    isLoading?: boolean;
    disabled?: boolean;
}
const SubmitButton = ({ text, isLoading, disabled }: SubmitButtonProps) => {
    if (isLoading)
        return (
            <LoadingSubmitButton>
                <LoadingSpinner color="black" />
            </LoadingSubmitButton>
        );
    return <StyledSubmitButton type="submit" value={text} disabled={disabled} />;
};

export default SubmitButton;
