import { DropzoneState } from 'react-dropzone';
import { Body, Comment } from '../typography.styled';
import { DragDropBody, DropArea } from './basic.styled';
import { getPlatforms } from '@ionic/react';

interface FiledropProps {
    state: DropzoneState;
    hint?: string;
}
const Filedrop = ({ state, hint }: FiledropProps) => {
    const { getRootProps, getInputProps, isDragActive } = state;
    const isMobile = !getPlatforms().includes('desktop');
    return (
        <DropArea {...getRootProps()}>
            <input {...getInputProps()} />
            <DragDropBody>
                {isDragActive ? (
                    <Body align="center">Drop here</Body>
                ) : (
                    <Body align="center">
                        {isMobile ? 'Tap to select a file' : 'Drop a file or click to use dialog'}
                    </Body>
                )}
                {hint && <Comment align="center">{hint}</Comment>}
            </DragDropBody>
        </DropArea>
    );
};

export default Filedrop;
